<svelte:options tag={"search-widget-web-component"} />

<script>
  import {
    handleAPIDtaInput,
    handleInputDetails,
    
  } from "./services/ddc2-search-service.js";

   /* import {ddc2_component } from "./services/ddc2-search.js"  */

  export let configuration;
  export let landingpage;
  export let searchvalue;
  export let toggleclick;
  export let filter;
  export let accesstoken
  export let embeddedsurveybtn;;

  export let search_instruction, search_placeholder, disable_search_instruction, disable_page_title;

  $: handleAPIDtaInput(configuration);
  $: handleInputDetails(searchvalue, landingpage, filter, accesstoken, toggleclick, search_instruction, search_placeholder, disable_search_instruction, disable_page_title, embeddedsurveybtn);

  import {
    labels,
    initialProductRefsList,
    closeDialog,
    closeTnc,
    excelSearchResponseReceived,
    isRTLCountry,
    isLandingPage,
    searchValue,
    toggleclickObj,
    apiDetailsObj,
    ddcSearchInstruction, ddcSearchPlaceholder, disableDDCSearchInstruction, disableDDCTitle, embeddedsurveybtnObj


  } from "./shared-data.js";
  import { getGuidedProduct } from "./services/data-service.js";
  import { SearchDocuments, DocRefClick ,trackingGuidedSearch} from "./services/analytics-service.js";
    import { onMount } from "svelte";
  let searchVal = "";
  let inputElement;
  let dynamicPlaceholder = "";
  let isSearchButtonDisabled = true;
  let dataReceived = false;
  let isCrossHidden = true;
  let isDialogOpen = false;
  let disabled = false;
  let isRTLCountryValue = false;
  let appsource;
  let searchLimit = 0;
  
  //let istoggleclick;
  // let toggleclick;

  let commRefLists;
  let Guidedsearch;
  let arrowlocation = 0;
  let arrow;

  let isMobileView = window.innerWidth <= 992;

  window.addEventListener('resize', () => {
    isMobileView = window.innerWidth <= 992;
  });

 /*  onMount(() => {
    const root = document.querySelector('search-widget-web-component')?.shadowRoot;
 
    if (root && !root.querySelector("#ddc2-search-style")) {
      const styleTag = document.createElement("style");
      styleTag.id = "ddc2-search-style";
      styleTag.setAttribute("nonce", "r4nd0m"); 
      styleTag.textContent = ddc2_component;
     
      root.appendChild(styleTag);
    }
  });  */

  window.addEventListener('notLandingpage',(data)=>{
    $isLandingPage = false;
  },true)
  
  isLandingPage.subscribe((data) => {
  });

  apiDetailsObj.subscribe((data) => {
    //toggleclick = data.toggleclick;
    //istoggleclick = toggleclick === "true" ? true : false;
    appsource = data.appSource;
    searchLimit = data.searchLimit;
    // console.warn("appSource: " + appsource);
  });

  // toggleclickObj.subscribe((toggleclick) => {
  //   istoggleclick = toggleclick === "true" ? true : false;
  // });

  searchValue.subscribe((val) => {
    searchVal = val || "";
  });

  excelSearchResponseReceived.subscribe((data) => {
    searchVal = "";
    isCrossHidden = true;
    isSearchButtonDisabled = true;
  });

  isRTLCountry.subscribe((data) => {
    isRTLCountryValue = data;
  });

  window.onload = function(){
  var divToHide = document.getElementById('HomePageInputBox');
  document.onclick = function(e){
    if(e.target.id !== 'HomePageInputBox'){
    commRefLists = [];
    }
  };
};

  async function handleInput(event) {
    searchVal=event.target.value;
    console.log("searchVal is", searchVal); 
    isSearchButtonDisabled = searchVal === "";
    isCrossHidden = searchVal === "";
  
    if (searchVal.length <= 3) {
      commRefLists = [];
    }
    if (searchVal === "") {
      isCrossHidden = true;
      commRefLists = [];
      clearSearch()
    }
    if(searchVal.length>=3 && searchLimit != 0){
      Guidedsearch = await getGuidedProduct(searchVal);
      commRefLists = "";
      commRefLists = Guidedsearch?.guidedSearchList;
    }
  }
  document.body.addEventListener("click", handleMenuClose);
  function handleMenuClose() {
    disabled = true;
    commRefLists = [];
  }

  function enterFunc() {
    arrow = true;
    arrowlocation = this.id;
  }

  function exitFunc() {
    arrow = false;
  }
  function exitFocus(){
    commRefLists = [];
  }

  function onKeyDown(e) {
    searchVal=e.target.value;
    /*console.log("searchVal isreeeeeeeeee", searchVal); */
    if(searchVal.length >= 3){
    if (!arrow && commRefLists?.length != 0) {
      if (e.keyCode == 38) {
        if (arrowlocation <= 0) {
          arrowlocation = commRefLists?.length - 1;
        } else {
          arrowlocation--;
        }
      }
      if (e.keyCode == 40) {
        if (arrowlocation >= commRefLists?.length - 1) {
          arrowlocation = 0;
        } else {
          arrowlocation++;
        }
      }
    }
    if (e.keyCode == 13) {
      SearchDocs();
    }
  }
  }
  
  function SearchDocs() {
    if (searchVal.length === 0) {
      console.log("SearchDocs:No value")
      return;
    }
    console.log("SearchDocs:seaarch for", searchVal)
      
    searchVal = searchVal;
    disabled = true;
    commRefLists = [];
    isLandingPage.set(false);
    SearchDocuments(searchVal);
    let landingPageVal;
    isLandingPage.subscribe((data)=>{
      landingPageVal = data
    })
    if(landingPageVal){
    clearSearch();
    }
  }

  closeDialog.subscribe((isClose) => {
    if (isClose) {
      isDialogOpen = false;
      closeDialog.set(false);
    }
  });
  closeTnc.subscribe((isClose) => {
    if (isClose) {
      flag = false;
      closeTnc.set(false);
    }
  });

  $: {
    searchVal;
    disabled = false;
  }

  // function handleSearchClick(selected) {
  //   if (!isSearchButtonDisabled) {
  //     initialProductRefsList.set([]);
  //     let reqObj = {
  //       searchType: selected ? "selectedDoc" : "search",
  //       label: searchVal.includes(':')?searchVal.split(":")[1].replace(/ /, ""):searchVal,
  //       reference: searchVal.includes(':')?searchVal.split(":")[0].replace(/ /, ""):null,
  //     };
  //     // isLandingPage.set(false);
  //     SearchDocuments(reqObj);
  //     isCrossHidden = false;
  //     dataReceived = true;
  //     disabled = true;
  //     if(landingpage){
  //     clearSearch();
  //     }
  //   }
  // }
  function dlhandleSearchClick(selected) {
    exitFocus();
    if (!isSearchButtonDisabled && searchVal.length > 0) {
      initialProductRefsList.set([]);
      isLandingPage.set(false);
      SearchDocuments(searchVal);
      isCrossHidden = false;
      dataReceived = true;
      disabled = true;
    }
  }

  function docRefClick(doc) {
    DocRefClick(doc.reference);
    
    isCrossHidden = false;
    dataReceived = true;
    disabled = true;
    searchVal = "";
    isCrossHidden = true;
    isSearchButtonDisabled = true;
    // if(landingpage){
    // clearSearch();
    // }
  }

  function clearSearch() {
    commRefLists = [];
    if(!$isLandingPage){
      searchVal = "";
      isCrossHidden = true;
      isSearchButtonDisabled = true;
      // Removing this call to fix: https://jira.se.com/browse/DCCD-4767
      // SearchDocuments('');
    }  else {
      searchVal = "";
      isCrossHidden = true;
      isSearchButtonDisabled = true;
    }
  }
</script>

<div 
  class={isRTLCountryValue == "true"
    ? "RTLCountry se-sw-container"
    : "se-sw-container"}
>
  <div
    class={$isLandingPage
      ? "se-sw-homepage-content"
      : "se-sw-landingpage-content"}
  >
    <div
      class="{$isLandingPage ? '' : 'container-block'} {$toggleclickObj === true
        ? 'toggle-container-block'
        : ''}"
    >
      {#if $isLandingPage}
        <div class="searchTitleAlignment">
          <h5 class="search-input-title search-title-margin">
            <span class="search-title-span">
              {$labels["prm-looking-for-a-document"]}
            </span>
          </h5>
        </div>
      {:else}
        <div class="container-sear">
          <qds-block color="none">
            <div class="titleBlock">
              {#if !$disableDDCTitle && $disableDDCTitle !== true}
                <h1 class="publictitle">{$labels["prm-download-center"]}</h1>
              {/if}
              {#if $embeddedsurveybtnObj === true}
                <slot name="embedded-survey-button"></slot>
              {/if}
            </div>
            <div class="badge-content">
              <slot name="advanced-badge"></slot>
            </div>
          </qds-block>
        </div>
      {/if}
      <qds-block
        class="se-block-search
        {$isLandingPage ? "" : "searchblock"}"
        color="none"
        
      >
        <div class={$isLandingPage ? "" : "search-box"}>
          {#if !$isLandingPage}
            <div class="search-text-comment">
              {#if ($ddcSearchInstruction && $ddcSearchInstruction != "") && ($disableDDCSearchInstruction && $disableDDCSearchInstruction == 'true')}
                {$ddcSearchInstruction}
                {:else}
                {$labels["prm-search-by-keyword-or-product"]}
              {/if}
            </div>
          {/if}
          <div class={$isLandingPage ? "" : "search-block-div"} row-size="60px">
            <div
              class={$isLandingPage
                ? "HomePageSearch search-input-container"
                : "search-input-container"}
            >
          <form on:submit|preventDefault={SearchDocs}
                block
                label-width="0"
                option="stacked"
                padding="none"
                class={$isLandingPage
                  ? "HomePageInputField search-input-form-field"
                  : "search-input-form-field"}
              >
                <div
                  
                  class="landing-container
                  {$isLandingPage ? "landingIcon" : ""}"
                >
                  {#if $isLandingPage}
                    <qds-input
                      class={$isLandingPage
                        ? "textColor HomePageInputBox"
                        : "inputText-textColor"}
                         data-size="large"
                      type="search"
                      id="HomePageInputBox"
                      placeholder={$labels["prm-search-by-keyword-or-product"]}
                      bind:this={inputElement}
                      value={searchVal}
                      on:click={clearSearch}
                      no-icon={!isMobileView}
                      on:input={(e) => searchVal = e.target.value}
                      on:input={handleInput}
                      on:keydown={onKeyDown}
                    />
                  {:else}
                    <qds-input
                      class={$isLandingPage
                        ? "textColor HomePageInputBox"
                        : "inputText-textColor"}
                      type="search"
                      size="large"
                      no-icon={!isMobileView}
                      id="HomePageInputBox"
                      placeholder={$labels["prm-search-button"]}
                      bind:this={inputElement}
                      value={searchVal}
                      on:click={clearSearch}
                      on:input={(e) => {
                        searchVal = e.target.value;
                        handleInput(e);
                      }}
                      on:keydown={(e) => {
                        searchVal = e.target.value;
                        onKeyDown(e);
                      }}
                
                      on:input={handleInput}
                      on:keydown={onKeyDown}
                    />
                  {/if}
                  
                  <ul option="dropdown" class={$isLandingPage
                    ? "search-list-block_landingpage"
                    : "search-list-block"}>
                    {#if commRefLists?.length > 0}
                      {#each commRefLists as index, i}
                        <li 
                        id={i}
                        
                            class="listing-component
                            {$isLandingPage ? "" : "search-result-li"}"
                            on:click={(e) => {
                              searchVal = index.label;
                              disabled = true;
                              docRefClick(index);
                            }}
                            on:keypress={(e) => {
                              searchVal = index.label;
                              disabled = true;
                              docRefClick(index);
                            }}
                            on:mouseenter={enterFunc}
                            on:mouseleave={exitFunc}
                            >
                            <!-- svelte-ignore a11y-invalid-attribute -->
                            <a href="JavaScript:Void(0)"   on:click={(e) => {
                              searchVal = index.label;
                              disabled = true;
                              docRefClick(index);
                              trackingGuidedSearch(index.label);
                            }} class="guidedList">{index.label}</a>
                          </li>
                      {/each}
                    {/if}
                  </ul>
                </div>
              </form>
              {#if !$isLandingPage}
              <qds-button
              slot="trigger"
              option="button"
              no-hover="true"
              size="large"
              importance="emphasized"
              icon-name="magnifying-glass"
              title={$labels["prm-search-button"]}
              on:click={() => dlhandleSearchClick()}
              on:focus={exitFocus}
              disabled={isSearchButtonDisabled || searchVal.length === 0}
              class="homepage-search-btn desktop-only"
              ></qds-button>
            {/if} 
              <div class="sa">
              {#if $isLandingPage}
                <qds-button
                
                importance="emphasized"
                icon-name="magnifying-glass"
                  option="outline"
                  title={$labels["prm-search-button"]}
                  on:click={SearchDocs}
                  on:focus={exitFocus}
                  disabled={isSearchButtonDisabled || searchVal.length === 0}
                  class="homepage-search-btn desktop-only"
                ></qds-button>
              {/if} 
            </div>

              <!-- search button mobile -->
              <!-- <div class="search-button-container search-button-container-mobile" /> -->
            </div>
          </div>
        </div>
      </qds-block>
    </div>
  </div>
</div>
<!-- <svelte:window on:keydown={onKeyDown} /> -->

<style>
   .titleBlock{
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
  }
  .badge-content {
    margin-bottom: 5px;
  }

  .badge-content:empty {
    display: none;
  }
  .se-sw-container {
    box-sizing: border-box;
  }
  .se-sw-homepage-content {
    width: 100%;
    /* border: 0.5px solid #cbcbcb; */
    /* padding: 16px; */
    margin: auto;
    box-sizing: border-box;
    background-color: #ffffff;
  }
  
  .se-sw-landingpage-content {
    /* max-width: 1140px; */
    box-sizing: border-box;
  }

  /* .toggle-container-block .search-icon {
    background-color: #025f87 !important;
  } */

  form {
    display: block;
    width: 100%;
  }
  .HomePageInputBox:focus::placeholder {
    color: transparent;
  }

  .publictitle {
    color: #333333;
    margin-top: 0px;
    margin-bottom: 12px;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 50px;
  }

  
  .RTLCountry {
    direction: rtl;
  }

  .RTLCountry .publictitle{
    text-align: right !important;
  }
  
  .RTLCountry .search-input-form-field {
    direction: rtl;
  }

 
  .search-box {
    /* margin: 0px 100px; */
    display: flex;
    flex-direction: column;
  }
  /* :is(se-list-item):focus{
    outline: none;
    background-color: #e7e6e6;
  } */
 

  li:hover {
    background-color: #e7e6e6;
  }
 
  .searchblock {
    /* border: 1px solid #e7e6e6; */
    box-sizing: border-box;
    padding: 0px 0px 24px 0px;
    /* background-color: #fff; */
  }
  .search-block-div{
    width: 700px;
  }

  .search-input-container {
    width: 100%;
    display: flex;
    position: relative;
  }
  .RTLCountry .search-input-container {
    float: right;
    direction: rtl;
  }

  .search-text-comment{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #333333;
    margin: 15px 0px 10px 1px;
  }
  .RTLCountry .search-text-comment{
    direction: rtl;
    text-align: right;
    width: auto;
  }

  .homepage-search-btn {
    
 
    /* min-width: 78px; */
    display: inline-table;
    /* padding-inline-start: 3px;  */
  }

  .RTLCountry .homepage-search-btn {
    min-width: 63px;
    display: inline-flex;
    margin-right: 4px;
  }

 
  .search-list-block_landingpage{
    position: absolute;
    top: 19px; 
   /*  max-width:460px; */
    width: auto;
    box-shadow: 0px 8px 16px 0px rgba(15, 15, 15, 0.2);
  }
  .search-list-block{
    position: absolute;
    top: 28px; 
    /* max-width:500px; */
    width: 99%;
    /* box-shadow: 0px 8px 16px 0px var(--se-shadow); */
    box-shadow: 0px 8px 16px 0px rgba(15, 15, 15, 0.2);
    max-height: 200px !important;
    overflow: auto;
    /* margin-top: 5px; */
  }
  ul {
    padding-left: 0;
    margin-left: 0;
    font-size: 0.875rem;
    height: auto;
    position: relative;
    /* width: 91%; */
    z-index: 10;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 12%);
    /* margin-top: 1px; */
  }

  li {
    display: block;
    padding: 4px 10px;
    /* border-bottom: 1px solid #e7e6e6; */
    background-color: #ffffff;
    color: #626469;
    /* white-space: nowrap; */
    /* overflow: hidden; */
    text-overflow: ellipsis;
    font-size: 14px !important;
    line-height: 20px;
    font-weight: 400;
    letter-spacing: 0px;
    border: 1px solid #e7e6e6;
    border-top: none;
    border-right: none;
    list-style: none;
  }
  
  .RTLCountry li{
    direction: rtl;
  }
  .RTLCountry .search-input-title span {
    margin-right: 0px !important;
  }
  @media screen and (max-width: 45.5625em) {
    .titleBlock{
      display: block;
    }

    .inputText-textColor{
      z-index: 1;
      width: 100% !important;
      
      /* margin-block: 10px; */
     /*  padding-inline:39px !important;  */
    }

    .search-result-li{
      margin-right: 0px;
    }

   

    .RTLCountry .inputText-textColor{
      padding: 4px 30px 4px 8px !important;
    }

    
    
    
  
    .search-block-div{
      width: auto;
      margin-block: 10px;
    }
    .se-sw-homepage-content {
      width: 100%;
    }
    .search-list-block_landingpage, .search-list-block{
      top: 34px;
      width: 90%;
    }
    .HomePageSearch {
      flex-direction: column;
      width: -webkit-fill-available;
    }
    .HomePageInputField {
      height: 45px;
      width: 100%;
    }

    .publictitle {
      line-height: 39px;
      font-size: 30px;
    }
    .container-block {
      margin-top: 25px;
      /* padding-right: 15px;
      padding-left: 15px; */
      margin-right: auto;
      margin-left: auto;
    }
    /* .search-box {
      margin: 0px 15px;
    } */
    .searchblock {
      padding: 0px 0px 15px 0px;
    }
    
  }

  /* for ipad pro 11 */
  @media screen and (max-width: 850px) and (min-width: 820px) {
  }

  /* for ipad pro 12 and screen width 1024 and above */
  @media screen and (min-width: 1024px) {
  }
  
  .container-block {
    margin-top: 25px;
  }
  .HomePageSearch {
    display: flex;
    align-items: baseline;
    margin-top: 0px;
    padding: 0px;
  }

  .HomePageInputField {
    max-width: 502px;
    margin-top: 0px;
    height: 45px;
  }

  .search-input-title {
    font-size: 16px;
    font-weight: 700;
    line-height: 25px;
    color: #333333;
  }

  .searchTitleAlignment {
    display: flex;
    margin-top: 5px;
    align-items: center;
  }

  .landingIcon {
    bottom: 3px;
    position: relative;
    align-items: center;
  }

  .HomePageInputBox {
    
    
    /* padding-right: 36px; */
    flex-grow: 1;
    /* height: 32px !important; */
    width: 100% !important;
    /* display: flex; */
     font-size: 15px; 
    text-transform: none;
    box-shadow: none !important;
  }
  .RTLCountry .HomePageInputBox{
    padding-right: 8px;
    padding-left: 36px;
  }
  .inputText-textColor {
    
  
    /* padding-right: 36px; */
    /* flex-grow: 1; */
   /*  height: 32px !important; */
     width: 100%;
    /* display: flex; */
    font-size: 14px;
    text-transform: none;
    box-shadow: none !important;
    
  }
  .inputText-textColor:focus,
  .HomePageInputBox:focus {
    border: 1px solid #1772ED !important;
    transition: none;
  }
  

  @media (max-width: 992px) {
    /* .toggle-container-block .search-icon {
      background: none !important;
    } */
    /* .inputText-textColor {
      text-transform: uppercase;
      border-radius: 2px 0 0 2px;
      padding: 4px 35px 4px 39px;
      box-shadow: none !important;
    } */
   
  }

  .RTLCountry .inputText-textColor {
    border-radius: 0px 2px 2px 0px;
    padding: 4px 10px 4px 8px;
  }

 

 

  @media screen and (max-width: 992px) {
    /* .search-icon {
      padding: 3px;
      border: none;
      background: none;
      color: black;
      font-size: 32px;
      bottom: 2px;
    } */
    /* .tooltip {
      position: absolute;
    } */

   
  }
  .guidedList{
    text-decoration: none;
    display: block;
    /* padding: 4px 10px; */
    background-color: #ffffff;
    color: #626469;
    text-overflow: ellipsis;
    font-size: 14px !important;
    line-height: 20px;
    font-weight: 400;
    letter-spacing: 0px;
    /* border: 1px solid #e7e6e6; */
    border-top: none;
    border-right: none;
    list-style: none;
  }
  .guidedList:hover {
    background-color: #e7e6e6;
}
  .desktop-only {
    border-radius: 0px 4px 4px 0px;
    background: #008a16 !important;
    margin-inline-start: -1px;
 
    display: block;
  }

  @media screen and (max-width: 992px) {
    .desktop-only {
      display: none;
    }
  }
  .search-title-margin {
    margin-top: 0px !important;
    margin-bottom: 8px !important;
  }

  .search-title-span {
    margin-right: 8px;
  }

  

  

  .se-block-search{
    --se-block-overflow: purple;
  }
  .landing-container{
    display:flex;
    position: relative;
  }
  .listing-component{
    cursor: pointer !important;
  }

</style>